import { invokeApi } from "../../utils/invokeApi";

export const addSalePage = async (data) => {
  const requestObj = {
    path: `api/sale_page/add_sale_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSalePage = async (data, slug) => {
  const requestObj = {
    path: `api/sale_page/update_sale_page/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailSalePage = async (slug) => {
  const requestObj = {
    path: `api/sale_page/detail_sale_page/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ListSalePage = async (data) => {
  const requestObj = {
    path: `api/sale_page/list_sale_page`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const changePageAccessApi = async (data) => {
  const requestObj = {
    path: `api/sale_page/control_page_access`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteSalePageApi = async (slug) => {
  const requestObj = {
    path: `api/sale_page/delete_sale_page/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const templateListingApi = async (data) => {
  const requestObj = {
    path: `api/template_configration/active_template_configration_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getMenuListApi = async (data) => {
  const requestObj = {
    path: `api/menu/list_menu`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const listThanksAndPaymentPage = async () => {
  const requestObj = {
    path: `api/sale_page/list_thanks_and_payement_page/selection`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const socailSharingUpdateApi = async (data, id) => {
  const requestObj = {
    path: `api/sale_page/update_page/social_sharing/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updatePageContentSettingApi = async (data, id) => {
  const requestObj = {
    path: `api/sale_page/update_sale_page_detail/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importTemplateDataApi = async (id) => {
  const requestObj = {
    path: `app/import_template_data`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: id,
  };
  return invokeApi(requestObj);
};
export const pageUnpublish = async (data) => {
  const requestObj = {
    path: `api/sale_page/update_page_access_info`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

import { filter } from "lodash";
import { useState } from "react";

import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Avatar,
  Checkbox,
  Chip,
  TextField,
  Pagination,
  Tooltip,
  Radio,
} from "@mui/material";
// import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import TableFooter from "@mui/material/TableFooter";

import { useEffect } from "react";
import UserListToolbarsForSearch from "./UserListToolbarsForSearch";
import MuiTableHeadList from "./MuiTableHeadList";
import CustomPopoverSection from "./CustomPopoverSection";
import SearchNotFound from "./SearchNotFound";
import UserListToolbarss from "./UserListToolbarss";
import ImageModelBox from "./ModelBox/ImageBox";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const filter_function = (query, _user, item, extra_filter) => {
  let search = query.toLowerCase();
  if (
    extra_filter?.some(
      (extra) => _user[extra]?.toString().toLowerCase().indexOf(search) !== -1
    )
  ) {
    return true;
  }

  if (
    item.type !== "link" &&
    item.type !== "row_status" &&
    item.type !== "transaction_Status" &&
    item.type !== "checkbox" &&
    item.type !== "row_calendar" &&
    item.type !== "thumbnail" &&
    item.type !== "action" &&
    item.type !== "category" &&
    !item.renderData &&
    item.type !== "number" &&
    item.type !== "subscription_status" &&
    item.type !== "member_status"
  ) {
    if (_user[item.id]?.toString().toLowerCase().indexOf(search) !== -1) {
      return true;
    }
  }
};

export default function CustomMUITable({
  TABLE_HEAD,
  data,
  MENU_OPTIONS,
  checkbox_selection,
  selected,
  setSelected,
  is_hide,
  hide_footer_pagination,
  hide_search,
  selected_by,
  className,
  custom_pagination,
  sortBy,
  custom_search,
  pageCount,
  totalPages,
  handleChangePages,
  pagePagination,
  rows_per_page_count,
  extra_filter,
  rowArray,
  localSearchName,
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(sortBy ? sortBy : "number");
  const [filterName, setFilterName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [tableInfo, setTableInfo] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(
    custom_pagination?.rows_per_page ?? rows_per_page_count ?? 50
  );

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };
  const handleOpen = (image_path) => {
    setImageUrl(image_path);
  };
  const handleClose = () => {
    setImageUrl("");
  };
  if (checkbox_selection && !selected) {
    selected = [];
  }

  const handleClick = (name) => {
    const selectedIndex = selected?.some((obj) => {
      if (selected_by && selected_by !== "") {
        return obj[selected_by] === name[selected_by];
      } else {
        return obj._id === name._id;
      }
    });

    if (selectedIndex === true) {
      let new_array = selected.filter((item) => {
        if (selected_by && selected_by !== "") {
          return item[selected_by] !== name[selected_by];
        } else {
          return item._id !== name._id;
        }
      });
      setSelected(new_array);
    } else {
      setSelected((selected) => [...selected, name]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(array, (_user) => {
        let searched_object = TABLE_HEAD.some((item) => {
          return filter_function(query, _user, item, extra_filter);
        });
        return searched_object;
      });
    }

    let filtered_data = stabilizedThis?.map((el) => el[0]);

    if (!custom_pagination?.rows_per_page) {
      filtered_data = filtered_data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
    return filtered_data;
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (localSearchName) {
      localStorage.setItem(localSearchName, event.target.value);
    }
  };

  const handleSearchText = (event) => {
    custom_search.setSearchText(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(data);
      return;
    }
    setSelected([]);
  };

  if (tableInfo) {
    tableInfo.addEventListener("click", function (event) {
      let target = event.target;
      if (target.tagName.toLowerCase() === "img") {
        let src = target.src;
        if (src) {
          handleOpen(src);
        }
      }
    });
  }

  useEffect(() => {
    if (localSearchName) {
      setFilterName(localStorage.getItem(localSearchName));
    }
    let table_element = document.getElementById("custom_mui_table");
    setTableInfo(table_element);
  }, []);

  useEffect(() => {
    handleOpen(imageUrl);
  }, [imageUrl]);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <Card
        style={{ overflowX: "auto" }}
        className={`mui-without-bg-custom-table ${className}`}
        id="custom_mui_table"
      >
        {custom_search ? (
          <>
            <div className="d-flex justify-content-between">
              {pagePagination && (
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ alignItems: "baseline" }}
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100, 200, 500]}
                    onRowsPerPageChange={
                      custom_pagination
                        ? custom_pagination.onRowsPerPageChange
                        : handleChangeRowsPerPage
                    }
                    component="div"
                    className="pt-3"
                    count={
                      custom_pagination
                        ? custom_pagination.total_count
                        : data.length
                    }
                    rowsPerPage={
                      custom_pagination
                        ? custom_pagination?.rows_per_page
                        : rowsPerPage
                    }
                    page={custom_pagination ? custom_pagination.page : page}
                    onPageChange={
                      custom_pagination
                        ? custom_pagination.handleChangePage
                        : handleChangePage
                    }
                  />
                  <Pagination
                    count={totalPages + 1}
                    page={pageCount}
                    defaultPage={0}
                    onChange={handleChangePages}
                    className="pagination-style"
                  />
                </div>
              )}
              <dv></dv>
              <UserListToolbarsForSearch
                filterName={custom_search.searchText}
                onFilterName={handleSearchText}
                handleSubmit={custom_search.handleSubmit}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`${
                pagePagination ? "d-flex justify-content-between" : "text-end"
              }`}
            >
              {pagePagination && (
                <div className="d-flex flex-column align-items-start">
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100, 200, 500]}
                    onRowsPerPageChange={
                      custom_pagination
                        ? custom_pagination.onRowsPerPageChange
                        : handleChangeRowsPerPage
                    }
                    className="table-pagination-box"
                    component="div"
                    count={
                      custom_pagination
                        ? custom_pagination.total_count
                        : data.length
                    }
                    rowsPerPage={
                      custom_pagination
                        ? custom_pagination?.rows_per_page
                        : rowsPerPage
                    }
                    page={custom_pagination ? custom_pagination.page : page}
                    onPageChange={
                      custom_pagination
                        ? custom_pagination.handleChangePage
                        : handleChangePage
                    }
                  />
                  {pageCount && (
                    <Pagination
                      count={totalPages + 1}
                      page={pageCount}
                      defaultPage={0}
                      onChange={handleChangePages}
                      className="pagination-style"
                    />
                  )}
                </div>
              )}
              {!is_hide && (
                <UserListToolbarss
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
              )}
            </div>
          </>
        )}

        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <MuiTableHeadList
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data.length}
              numSelected={checkbox_selection && selected.length}
              onRequestSort={handleRequestSort}
              checkbox_selection={checkbox_selection}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers?.map((row, index) => {
                const isItemSelected =
                  selected?.length < 1
                    ? false
                    : selected?.some((obj) => {
                        if (selected_by && selected_by !== "") {
                          return obj[selected_by] === row[selected_by];
                        } else {
                          return obj._id === row._id;
                        }
                      });

                return (
                  <TableRow
                    hover
                    key={index}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {checkbox_selection && (
                      <TableCell align="left" width={50}>
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleClick(row)}
                        />
                      </TableCell>
                    )}
                    {TABLE_HEAD?.map((head, i) => {
                      if (head.type === "checkbox") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            {}
                            <Checkbox
                              checked={row[head.id]}
                              onChange={(e) => head.handleClick(e, row, index)}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "radio_button") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Radio
                              checked={row[head.id]}
                              onChange={(e) => head.handleClick(e, row, index)}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "number") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <span
                              className={row.className}
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            >
                              {index +
                                1 +
                                (custom_pagination
                                  ? custom_pagination?.rows_per_page *
                                    custom_pagination?.page
                                  : rowsPerPage * page)}
                            </span>
                          </TableCell>
                        );
                      } else if (head.type === "paid_status") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              width="140px"
                              label={row[head.id] === true ? "Unlock" : "Lock"}
                              variant="contained"
                              className={
                                row[head.id] === true
                                  ? `manage-program-chip-success ${row.className}`
                                  : ""
                              }
                              sx={{
                                backgroundColor:
                                  row[head.id] === true ? "#5FAE1B" : "#e03542", // Custom colors
                                color: "#fff", // Text color
                              }}
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "transaction_Status") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              label={
                                row[head.id] === "succeeded"
                                  ? "Succeeded"
                                  : row[head.id] === "requires_action"
                                  ? "Require Action"
                                  : "Failed"
                              }
                              variant="contained"
                              className={`manage-program-chip ${
                                row[head.id] === "succeeded"
                                  ? "chip-success"
                                  : row[head.id] === "require_action"
                                  ? "chip-require-action"
                                  : "chip-failed"
                              }`}
                              sx={{
                                backgroundColor:
                                  row[head.id] === "succeeded"
                                    ? "green"
                                    : row[head.id] === "requires_action"
                                    ? "blue"
                                    : "red",
                                color: "#fff",
                              }}
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "subscription_status") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              label={
                                row[head.id] === "active"
                                  ? "Active"
                                  : row[head.id] === "canceled"
                                  ? "Cancelled"
                                  : "Pending"
                              }
                              variant="contained"
                              className={`manage-program-chip ${
                                row[head.id] === "active"
                                  ? "chip-success"
                                  : row[head.id] === "canceled"
                                  ? "chip-canceled"
                                  : "chip-pending"
                              }`}
                              sx={{
                                backgroundColor:
                                  row[head.id] === "active"
                                    ? "green"
                                    : row[head.id] === "canceled"
                                    ? "gold	"
                                    : "red",
                                color: "#fff",
                              }}
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "member_status") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              label={
                                row[head.id] === "active"
                                  ? "Active"
                                  : "Cancelled"
                              }
                              variant="contained"
                              className={`manage-program-chip ${
                                row[head.id] === "active"
                                  ? "chip-success"
                                  : "chip-canceled"
                              }`}
                              sx={{
                                backgroundColor:
                                  row[head.id] === "active" ? "green" : "gold	",
                                color: "#fff",
                              }}
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "row_status") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              width="140px"
                              label={
                                row[head.id] === true ? "Active" : "Inactive"
                              }
                              variant="contained"
                              className={
                                row[head.id] === true
                                  ? `manage-program-chip-success ${row.className}`
                                  : ""
                              }
                              color={
                                row[head.id] === true ? "success" : "error"
                              }
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "new_payment") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Chip
                              width="140px"
                              label={row[head.id] ? "Active" : "Failed"} // Adjusted to show 'active' or 'failed' based on transaction_status
                              variant="contained"
                              className={
                                row[head.id]
                                  ? `manage-program-chip-success ${row.className}`
                                  : `manage-program-chip-failed ${row.className}` // Added class for 'failed' state
                              }
                              color={row[head.id] ? "success" : "error"} // Sets color based on status
                              size="small"
                              onClick={() => {
                                if (head.handleClick) {
                                  head.handleClick(row, index);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      } else if (head.type === "thumbnail") {
                        return (
                          <TableCell
                            className={head.className}
                            align="left"
                            key={i}
                          >
                            <Tooltip
                              title={
                                row[head.id].src !== "/"
                                  ? `View ${head.label}`
                                  : ""
                              }
                            >
                              <Avatar
                                className={
                                  row[head.id].src !== "/"
                                    ? "cursor-pointer"
                                    : ""
                                }
                                alt={row[head.id]?.alt}
                                src={row[head.id]?.src}
                                onClick={() => {
                                  if (row[head.id].src !== "/") {
                                    handleOpen(row[head.id]?.src);
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        );
                      } else if (head.type === "link") {
                        return (
                          <TableCell className={head.className} key={i}>
                            {row[head.id].show_text ? (
                              <a
                                href={row[head.id].to}
                                className={row[head.id].className}
                                target={row[head.id].target}
                              >
                                {row[head.id].show_text}
                              </a>
                            ) : row[head.id].show_alternate_text ? (
                              row[head.id].show_alternate_text
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      } else if (head.type === "action") {
                        return (
                          <TableCell
                            align="left"
                            className={head.className}
                            key={i}
                          >
                            {(row[head.MENU_OPTIONS]?.length > 0 ||
                              MENU_OPTIONS?.length > 0) && (
                              <CustomPopoverSection
                                menu={
                                  row[head.MENU_OPTIONS]
                                    ? row[head.MENU_OPTIONS]
                                    : MENU_OPTIONS
                                }
                                data={row}
                              />
                            )}
                          </TableCell>
                        );
                      } else if (head.type === "html") {
                        return (
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            className={head.className}
                            key={i}
                          >
                            <div
                              className={row.className}
                              dangerouslySetInnerHTML={{
                                __html: row[head.id],
                              }}
                            ></div>
                          </TableCell>
                        );
                      } else if (head.type === "title_with_img") {
                        return (
                          <TableCell
                            align="left"
                            className={head.className}
                            key={i}
                          >
                            <div className="d-flex align-items-center">
                              <Avatar
                                src={row[head.id]?.image}
                                alt={row[head.id]?.title}
                                className={`me-2 ${row[head.id]?.class}`}
                              />
                              <>{row[head.id]?.title}</>
                            </div>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            align="left"
                            className={head.className}
                            key={i}
                          >
                            {head.renderData ? (
                              head.renderData(row, index)
                            ) : (
                              <span
                                className={row.className}
                                onClick={() => {
                                  if (head.handleClick) {
                                    head.handleClick(row, index);
                                  }
                                }}
                              >
                                {row[head.id]}
                              </span>
                            )}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={
                      checkbox_selection
                        ? TABLE_HEAD?.length + 1
                        : TABLE_HEAD?.length
                    }
                    sx={{ py: 3 }}
                  >
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TableFooter className="mui-table-footer">
          <div
            className={`rows_selected_text ${
              hide_footer_pagination ? "pb-3" : ""
            }`}
            style={{ alignItems: "baseline" }}
          >
            {checkbox_selection && selected.length > 0
              ? `${selected.length} ${
                  selected.length > 1 ? "rows" : "row"
                } selected`
              : ""}
          </div>
          {!hide_footer_pagination && (
            <TablePagination
              rowsPerPageOptions={rowArray ?? [10, 20, 50, 100, 200, 500]}
              onRowsPerPageChange={
                custom_pagination
                  ? custom_pagination.onRowsPerPageChange
                  : handleChangeRowsPerPage
              }
              className="table-pagination-box"
              component="div"
              count={
                custom_pagination ? custom_pagination.total_count : data.length
              }
              rowsPerPage={
                custom_pagination
                  ? custom_pagination?.rows_per_page
                  : rowsPerPage
              }
              page={custom_pagination ? custom_pagination.page : page}
              onPageChange={
                custom_pagination
                  ? custom_pagination.handleChangePage
                  : handleChangePage
              }
            />
          )}
        </TableFooter>
        {pagePagination && pageCount ? (
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        ) : (
          ""
        )}
      </Card>
      {imageUrl && (
        <ImageModelBox
          open={true}
          handleClose={handleClose}
          image_url={imageUrl}
        />
      )}
    </>
  );
}

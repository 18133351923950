import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { membersListApi, deleteMemberApi } from "../../DAL/members";
import { s3baseUri } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import CustomConfirmation from "../../components/generalComponents/CustomConfirmation";

const Members = () => {
  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    members: [],
  };

  const [memberState, setMemberState] = useState(INITIAL_VALUES);
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingMemberId, setDeletingMemberId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setIsLoading(true); // Set loading to true before fetching data
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "name_profile", label: "Name ", type: "title_with_img" },
    { id: "email", label: "Email", type: "email" },
    { id: "gender", label: "Gender" },
    { id: "school", label: "School" },
    { id: "is_member_paid", label: "Availability", type: "paid_status" },
    { id: "action_by", label: "Added From" },
    { id: "status", label: "Status", type: "row_status" },
  ];
  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const fetchData = async () => {
    const postData = { search: searchText };
    const response = await membersListApi(page, rowsPerPage, postData);

    if (response.code === 200) {
      const members = response.members.map((member) => ({
        ...member,

        name_profile: {
          title: member.name,
          image: s3baseUri + member.profile_image?.thumbnail_1,
        },
        date_of_birth_iso: member.date_of_birth,
        date_of_birth: moment(member.date_of_birth).format("DD/MM/YYYY hh:mm"),
        biography: member.biography,
        action_by: capitalizeWords(member.action_info?.action_by || "N/A"),
        status: member.user_id.status,
        email: member.user_id.email,
        school: member.school_info
          ? member.school_info.school_name
            ? member.school_info.school_name
            : "No School"
          : "No School",
      }));

      setTotalCount(response.total_members);
      setTotalPages(Math.ceil(response.total_members / rowsPerPage) - 1);
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const searchFunction = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleDeleteMember = async (memberId) => {
    setDeleteLoading(true);

    const response = await deleteMemberApi(memberId._id);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setDeleteDialogOpen(false);
      fetchData();
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setDeleteDialogOpen(false);
    }
    setDeleteLoading(false);
  };

  const handleAddMember = () => {
    navigate("/members/add-members");
  };

  const handleEdit = (row) => {
    navigate(`/members/edit-members`, {
      state: { member: row },
    });
  };

  const handleDelete = (row) => {
    setDeletingMemberId(row.user_id);
    setDeleteDialogOpen(true);
  };

  const handleFamilyMembers = (row) => {
    navigate(`/members/family-member-operation/${row.user_id}`);
  };
  const handlesubscription = (row) => {
    navigate(`/members/members-subscription/${row._id}`, {
      state: { program: row },
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: (row, index) => handleEdit(row),
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: (row, index) => handleDelete(row),
    },
    {
      label: "Family Members",
      icon: "radix-icons:font-family",
      handleClick: (row, index) => handleFamilyMembers(row),
    },
    {
      label: " Subscriptions",
      icon: "fluent-mdl2:subscribe",
      handleClick: (row, index) => handlesubscription(row),
    },
  ];

  if (isLoading) return <PageLoading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>Members</h4>
        </div>
        <div className="col-6 my-2 text-end">
          <button className="theme-button-contained" onClick={handleAddMember}>
            Add Member
          </button>
        </div>
        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            pagePagination={true}
            MENU_OPTIONS={MENU_OPTIONS}
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
          />
        </div>
      </div>

      <CustomConfirmation
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={"Are you sure you want to delete this member?"}
        handleAgree={() => handleDeleteMember(deletingMemberId)}
      />
    </div>
  );
};

export default Members;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
  Switch,
  Chip,
} from "@mui/material";
import { CircularProgress } from "@mui/material";

import CustomMUITable from "../../components/customMUITable/CustomMUITable";
import { deleteMemberApi } from "../../DAL/members";
import { client_url } from "../../config/config";
import PageLoading from "../../components/generalComponents/PageLoading";
import { enqueueSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  changePageAccessApi,
  deleteSalePageApi,
  importTemplateDataApi,
  ListSalePage,
} from "../../DAL/webPages/webPages";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function TemplatePages() {
  const classes = useStyles();

  const INITIAL_VALUES = {
    selectedRow: null,
    loading: true,
    confirmDeleteOpenMember: false,
    drawerOpen: false,
    selectedCategory: null,
    members: [],
  };
  const location = useLocation();

  const is_website_pages = window.location.pathname.includes("website-pages");
  const [openDelete, setOpenDelete] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [pageSlug, setPageSlug] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [importingPageId, setImportingPageId] = useState("");
  const [changeAccessData, setChangeAccessData] = useState();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleChangePageAccess = async () => {
    let postData = {
      sale_page_id: changeAccessData._id,
      page_access: changeAccessData.page_access
        ? !changeAccessData.page_access
        : true,
    };
    const result = await changePageAccessApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getPagesList();
      setOpenConfirmation(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenConfirmation(false);
    }
  };
  const handleConfirmation = (value) => {
    delete value.MENU_OPTIONS;
    setChangeAccessData(value);
    setOpenConfirmation(true);
  };

  const handleAgreeImportData = (value) => {
    setOpenTemplate(true);
    setImportingPageId(value._id);
  };
  const handleImport = async () => {
    setOpenTemplate(false);
    setIsLoading(true);
    const dataObject = {
      sale_page_id: importingPageId,
    };

    const result = await importTemplateDataApi(dataObject);
    if (result.code === 200) {
      getPagesList();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "sale_page_title", label: "Page Title" },
    {
      id: "preview_link_data",
      label: "URL",
      renderData: (row) => {
        if (
          row.type_of_page == "payment_page" ||
          row.type_of_page == "thanks_page"
        ) {
          return <></>;
        } else {
          if (
            is_website_pages &&
            row.is_page_template_available &&
            !row.is_template_data_imported
          ) {
            return (
              <Tooltip title="Click to import template data">
                <Chip
                  label="Import Template Data"
                  color="primary"
                  variant="outlined"
                  // onClick={() => handleAgreeImportData(row)}
                />
              </Tooltip>
            );
          } else {
            return (
              <a
                // href={"https://club.core.kids/"}
                href={row?.preview_link_data?.to}
                className="anchor-style"
                target="_blank"
              >
                {/* Preview */}
                {row.status == true ? "Preview" : ""}
              </a>
            );
          }
        }
      },
    },
    {
      id: "page_access",
      label: "Page Access",
      renderData: (row) => {
        return (
          <Tooltip title="Change Page Access">
            <Switch
              checked={row.page_access}
              onChange={() => handleConfirmation(row)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        );
      },
    },
    {
      label: "Copy URL",
      type: "url",
      renderData: (row) => {
        if (
          row.type_of_page == "payment_page" ||
          row.type_of_page == "thanks_page"
        ) {
          return <></>;
        } else {
          if (
            is_website_pages &&
            row.is_page_template_available &&
            !row.is_template_data_imported
          ) {
            return <></>;
          } else {
            return (
              <CopyToClipboard
                text={
                  row.page_type == "landing"
                    ? client_url
                    : client_url + row.sale_page_title_slug
                }
              >
                <Tooltip title="Click to copy Preview URL">
                  <Chip label="Copy URL" color="primary" variant="outlined" />
                </Tooltip>
              </CopyToClipboard>
            );
          }
        }
      },
    },
  ];

  const handleUpdatePageContent = (value) => {
    delete value.MENU_OPTIONS;

    if (is_website_pages) {
      navigate(
        `/website-pages/update-page-content/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/template-pages/update-page-content/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    }
  };
  const handleSocialSharing = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/social-sharing-setting/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/template-pages/social-sharing-setting/${value.sale_page_title_slug}`,
        {
          state: value,
        }
      );
    }
  };
  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(`/website-pages/edit-page/${value.sale_page_title_slug}`, {
        state: value,
      });
    } else {
      navigate(`/template-pages/edit-page/${value.sale_page_title_slug}`, {
        state: value,
      });
    }
  };
  const handleUnpublishSetting = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(`/website-pages/unpublish-setting/${value._id}`, {
        state: value,
      });
    } else {
      navigate(`/template-pages/unpublish-setting/${value._id}`, {
        state: value,
      });
    }
  };
  const handleEditPaymentPage = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/edit-page/${value.payment_page.sale_page_title_slug}`,
        {
          state: value.payment_page,
        }
      );
    } else {
      navigate(
        `/template-pages/edit-page/${value.payment_page.sale_page_title_slug}`,
        {
          state: value.payment_page,
        }
      );
    }
  };
  const handleUpdatePaymentPageContent = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/update-page-content/${value.payment_page.sale_page_title_slug}`,
        {
          state: value.payment_page,
        }
      );
    } else {
      navigate(
        `/template-pages/update-page-content/${value.payment_page.sale_page_title_slug}`,
        {
          state: value.payment_page,
        }
      );
    }
  };
  const handleCopyAppointmentURL = (value) => {};
  const handleQuestionAnswers = (value) => {};
  const handlePageSubscriber = (value) => {};
  const handleUpdateThanksPageContent = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/update-page-content/${value.thanks_page.sale_page_title_slug}`,
        {
          state: value.thanks_page,
        }
      );
    } else {
      navigate(
        `/template-pages/update-page-content/${value.thanks_page.sale_page_title_slug}`,
        {
          state: value.thanks_page,
        }
      );
    }
  };
  const handleEditThanksPage = (value) => {
    delete value.MENU_OPTIONS;
    if (is_website_pages) {
      navigate(
        `/website-pages/edit-page/${value.thanks_page.sale_page_title_slug}`,
        {
          state: value.thanks_page,
        }
      );
    } else {
      navigate(
        `/template-pages/edit-page/${value.thanks_page.sale_page_title_slug}`,
        {
          state: value.thanks_page,
        }
      );
    }
  };
  const handleDeletePages = (e) => {
    setOpenDelete(true);
    setPageSlug(e?.sale_page_title_slug);
  };
  const handleAgreeDeletePage = async (e) => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteSalePageApi(pageSlug);
    if (result.code == 200) {
      getPagesList();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleRedirect = (module, value, redirect) => {
    if (redirect == "thanks_page") {
      value = value.thanks_page;
    } else if (redirect == "payment_page") {
      value = value.payment_page;
    }
    if (module.module_configuration_type == "payment_plan") {
      if (is_website_pages) {
        navigate(`/website-pages/${value._id}/payment-plans`, {
          state: {
            data: value,
            module: module,
          },
        });
      } else {
        navigate(`/template-pages/${value._id}/payment-plans`, {
          state: {
            data: value,
            module: module,
          },
        });
      }
    } else if (is_website_pages) {
      navigate(
        `/website-pages/${value._id}/${module.module_configuration_slug}`,
        {
          state: module,
        }
      );
    } else {
      navigate(
        `/template-pages/${value._id}/${module.module_configuration_slug}`,
        {
          state: module,
        }
      );
    }
  };

  const menuHandling = (value, pagesDefault) => {
    const MENU_OPTIONS = [
      {
        label: "Edit Page Setting",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Social Sharing Setting",
        icon: "akar-icons:edit",
        handleClick: handleSocialSharing,
      },
      {
        label: "Update Page Content",
        icon: "akar-icons:edit",
        handleClick: handleUpdatePageContent,
      },

      {
        label: "Page Unpublish Setting",
        icon: "akar-icons:edit",
        handleClick: handleUnpublishSetting,
      },
    ];
    if (value.type_of_page == "book_a_call_page") {
      MENU_OPTIONS.splice(3, 0, {
        label: "Copy Appointment URL",
        icon: "eva:eye-fill",
        handleClick: handleCopyAppointmentURL,
      });
      MENU_OPTIONS.splice(4, 0, {
        label: "Question Answers",
        icon: "eva:eye-fill",
        handleClick: handleQuestionAnswers,
      });
    }
    // MENU_OPTIONS.push({
    //   label: "View Subscribers",
    //   icon: "eva:eye-fill",
    //   handleClick: handlePageSubscriber,
    // });
    // for thanks page in menu optios
    if (value.thanks_page) {
      let child_menu_options = [
        {
          label: "Edit Page Setting",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handleEditThanksPage(e);
          },
        },

        {
          label: "Update Page Content",
          icon: "akar-icons:edit",
          handleClick: handleUpdateThanksPageContent,
        },
      ];
      value.thanks_page?.module_info?.map((module) => {
        child_menu_options.push({
          label: module.module_label_text,
          icon: "akar-icons:edit",
          handleClick: () => {
            handleRedirect(module, value, "thanks_page");
          },
        });
      });
      MENU_OPTIONS.push({
        label: "Thanks Page",
        icon: "akar-icons:edit",
        child_options: child_menu_options,
      });
    }

    if (value.payment_page) {
      let child_menu_options = [
        {
          label: "Edit Page Setting",
          icon: "akar-icons:edit",
          handleClick: (e) => {
            handleEditPaymentPage(e);
          },
        },
        {
          label: "Update Page Content",
          icon: "akar-icons:edit",
          handleClick: handleUpdatePaymentPageContent,
        },
      ];
      value.payment_page?.module_info?.map((module) => {
        child_menu_options.push({
          label: module.module_label_text,
          icon: "akar-icons:edit",
          handleClick: () => {
            handleRedirect(module, value, "payment_page");
          },
        });
      });
      MENU_OPTIONS.push({
        label: "Payment Page",
        icon: "akar-icons:edit",
        child_options: child_menu_options,
      });
    }

    // if (
    //   is_website_pages &&
    //   value.is_page_template_available &&
    //   !value.is_template_data_imported
    // ) {
    //   MENU_OPTIONS.push({
    //     label: "Import Template Data",
    //     icon: "akar-icons:edit",
    //     handleClick: handleAgreeImportData,
    //   });
    // }

    value.module_configurations &&
      value.module_configurations.map((module) => {
        MENU_OPTIONS.push({
          label: module.module_configuration_name,
          icon: "akar-icons:edit",
          handleClick: () => {
            handleRedirect(module, value, "sale_page");
          },
        });
      });

    // value.module_info.map((module) => {
    //   MENU_OPTIONS.push({
    //     label: module.module_label_text,
    //     icon: "akar-icons:edit",
    //     handleClick: () => {
    //       handleRedirect(module, value, "sale_page");
    //     },
    //   });
    // });

    // let menu123 = pagesDefault?.some((page) => page._id == value._id);
    if (is_website_pages) {
      // if (!menu123) {
      MENU_OPTIONS.push({
        label: "Delete Page",
        icon: "ant-design:delete-twotone",
        handleClick: (e) => {
          handleDeletePages(e);
        },
      });
      // }
    }

    return MENU_OPTIONS;
  };
  const navigate = useNavigate();
  const handleAddPage = () => {
    navigate("/website-pages/add-page");
  };

  const handleDeleteMember = async (memberId) => {
    const response = await deleteMemberApi(memberId);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
      setMembersList(
        membersList.filter((member) => member.user_id !== memberId)
      );
      setDeleteDialogOpen(false);
    } else {
      setDeleteDialogOpen(false);
    }
  };
  const getPagesList = async () => {
    setIsLoading(true);
    let postData = {
      type: is_website_pages ? "general" : "template",
    };
    const response = await ListSalePage(postData);
    if (response.code == 200) {
      let members = response.sale_pages.map((member) => {
        return {
          ...member,
          MENU_OPTIONS: menuHandling(member, response?.default_pages),
          preview_link_data: {
            to:
              member.page_type == "landing"
                ? client_url
                : client_url + member.sale_page_title_slug,
            target: "_blank",
            show_text: "Preview",

            className: "anchor-style",
          },
        };
      });
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getPagesList();
  }, [is_website_pages]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 my-2 members_heading">
          <h4>{is_website_pages ? "Pages" : "Template Pages"}</h4>
        </div>
        {is_website_pages && (
          <div className="col-6 my-2 text-end">
            <button className="theme-button-contained" onClick={handleAddPage}>
              Add New Page
            </button>
          </div>
        )}

        <div className="col-12">
          <CustomMUITable
            TABLE_HEAD={TABLE_HEAD}
            data={membersList}
            pagePagination={true}
            // MENU_OPTIONS={MENU_OPTIONS}
          />
        </div>
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => handleDeleteMember()} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <DialogTitle>Change Access</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change access?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmation(false)}>Cancel</Button>
          <Button onClick={() => handleChangePageAccess()} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Delete Page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button onClick={() => handleAgreeDeletePage()} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTemplate} onClose={() => setOpenTemplate(false)}>
        <DialogTitle>Import Template Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="confirmation-popup-title">
              <h2>Are you sure you want to import template data?</h2>
              <p>
                Importing template data will update page content and copy other
                modules data.
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTemplate(false)}>Cancel</Button>
          <Button onClick={() => handleImport()} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
